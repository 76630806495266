<template>
	<div>
		<b-card class="mr-1 p-1" no-body>
			<b-row>
				<b-col class="d-flex align-items-center" cols="10">
					<b-nav pills class="m-0" style="gap: 1rem">
						<b-nav-item
							:to="{ name: 'management-petty-cash' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Caja chica
						</b-nav-item>
						<b-nav-item
							:to="{ name: 'management-warehouse' }"
							tag="li"
							active-class="nav-link active active-item-nav"
							link-classes="active-item-nav"
						>
							Productos
						</b-nav-item>
					</b-nav>
				</b-col>
				<b-col class="d-flex justify-content-end" style="gap: 1rem">
					<!-- <b-button
						variant="primary"
						v-if="getRouteName == 'management-petty-cash'"
						@click="balanceModal = true"
					>
						Ver saldos
					</b-button> -->
					<b-button variant="success" v-if="getRouteName == 'management-petty-cash'" @click="createPettyCash">
						Crear caja chica
					</b-button>

					<b-button variant="info" v-if="getRouteName == 'management-warehouse'" @click="showOperation">
						<!-- <feather-icon icon="PlusIcon" style="font-weight: 900 !important" /> -->
						Administrar stock
					</b-button>

					<b-button variant="success" v-if="getRouteName == 'management-warehouse'" @click="createProduct">
						Crear producto
					</b-button>
				</b-col>
			</b-row>
		</b-card>
		<b-card class="p-1" no-body>
			<router-view ref="view-component"></router-view>
		</b-card>

		<CashBalancesModal v-if="balanceModal" @close="balanceModal = false" />
	</div>
</template>

<script>
import CashBalancesModal from "@/views/amg/management/views/petty-cash/components/CashBalancesModal.vue"

export default {
	name: "ManagementMain",
	components: { CashBalancesModal },
	data() {
		return {
			balanceModal: false,
		}
	},
	computed: {
		getRouteName() {
			return this.$route.name
		},
	},
	created() {},
	methods: {
		createPettyCash() {
			this.$refs["view-component"].createPettyCash()
		},

		createProduct() {
			this.$refs["view-component"].createProduct()
		},

		showOperation() {
			this.$refs["view-component"].showOperation()
		},
	},
}
</script>

<style scoped lang="scss"></style>
